@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
/* LIGHT MODE + GENERAL */

.react-calendar {
	border: unset;
	background-color: transparent;
	font-family: "DM Sans", sans-serif;
}
.react-calendar__navigation__prev2-button {
	display: none;
}
.react-calendar__navigation__next2-button {
	display: none;
}
.react-calendar__navigation {
	align-items: center;
}
abbr[title] {
	border-bottom: none;
	-webkit-text-decoration: unset;
	text-decoration: unset;
	-webkit-text-decoration: unset;
	-webkit-text-decoration: unset;
	text-decoration: unset !important;
}
.react-calendar__navigation__prev-button {
	background-color: #4318ff !important;
	border-radius: 10px;
	min-width: 34px !important;
	height: 34px !important;
	color: white;
}
.react-calendar__navigation__next-button {
	background-color: #4318ff !important;
	border-radius: 10px;
	min-width: 34px !important;
	width: 34px !important;
	height: 34px !important;
	color: white;
}
.react-calendar__navigation__label {
	font-weight: 700 !important;
	font-size: 18px;
}
.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
	background-color: unset !important;
	border-radius: 10px;
}
.react-calendar__tile {
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px !important;
	height: 34px !important;
	color: #1b2559;
}
.react-calendar__month-view__weekdays {
	background-color: #f4f7fe;
	border-radius: 10px;
	margin-bottom: 6px;
}
.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background-color: #f4f7fe;
	border-radius: 10px;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #b0bbd5;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #4318ff;
	border-radius: 10px;
	color: white;
}
.react-calendar__tile--range,
.react-calendar__tile--range:enabled:hover,
.react-calendar__tile--range:enabled:focus {
	background: #f4f7fe;
	color: #4318ff;
	border-radius: 0px;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus {
	background: #4318ff;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	color: white;
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
	background: #4318ff;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	color: white;
}

/* DARK MODE */

body.dark .react-calendar {
	border-radius: 30px;
}
body.dark .react-calendar__navigation__prev-button {
	background-color: #7551ff !important;
}
body.dark .react-calendar__navigation__next-button {
	background-color: #7551ff !important;
	color: white;
}
body.dark .react-calendar__tile {
	color: white;
}
body.dark .react-calendar__tile:enabled:hover,
body.dark .react-calendar__tile:enabled:focus {
	background-color: rgba(255, 255, 255, 0.1);
}
body.dark .react-calendar__month-view__weekdays {
	background-color: rgba(255, 255, 255, 0.1);
}
body.dark .react-calendar__tile--now,
body.dark .react-calendar__tile--now:enabled:hover,
body.dark .react-calendar__tile--now:enabled:focus {
	background-color: rgba(255, 255, 255, 0.1);
}
body.dark .react-calendar__month-view__days__day--neighboringMonth {
	color: #b0bbd5;
}
body.dark .react-calendar__tile--active,
body.dark .react-calendar__tile--active:enabled:hover,
body.dark .react-calendar__tile--active:enabled:focus {
	background: #7551ff;
	color: white;
}
body.dark .react-calendar__tile--range,
body.dark .react-calendar__tile--range:enabled:hover,
body.dark .react-calendar__tile--range:enabled:focus {
	background: rgba(255, 255, 255, 0.1);
	color: #7551ff;
}
body.dark .react-calendar__tile--rangeStart,
body.dark .react-calendar__tile--rangeStart:enabled:hover,
body.dark .react-calendar__tile--rangeStart:enabled:focus {
	background: #7551ff;
	color: white;
}
body.dark .react-calendar__tile--rangeEnd,
body.dark .react-calendar__tile--rangeEnd:enabled:hover,
body.dark .react-calendar__tile--rangeEnd:enabled:focus {
	background: #7551ff;
	color: white;
}