/* Using box model */
* {
    box-sizing: border-box;
  }
  
  /* Maps examples */
  .examples {
      width: 1000px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
      text-align: center;
  
      &__title {
          margin-bottom: 80px;
          padding-bottom: 40px;
          border-bottom: 1px dotted black;
          font-size: 24px;
      }
  
      &__block {
          margin-bottom: 80px;
          padding-bottom: 80px;
          border-bottom: 1px dotted black;
  
  
          &__title {
              margin-bottom: 40px;
              font-size: 20px;
          }
  
          &__info {
              display: inline-block;
              vertical-align: top;
              width: 450px;
              text-align: left;
  
              &__item {
                  margin-bottom: 20px;
              }
          }
  
          &__map {
              display: inline-block;
              width: 500px;
  
              &--australia {
                  .svg-map {
                      stroke-width: 0.5; // Australia stroke is larger than other maps
                  }
              }
  
              &--cameroon {
  
                  .svg-map__location {
                      &--heat0 {
                          fill: blue;
                      }
  
                      &--heat1 {
                          fill: lightblue;
                      }
  
                      &--heat2 {
                          fill: orange;
                      }
  
                      &--heat3 {
                          fill: rgb(87, 24, 24);
                      }
  
                      &--heat4 {
                          fill: rgb(31, 131, 145);
                      }
  
                      &--heat5 {
                          fill: rgb(104, 23, 121);
                      }
  
                      &--heat6 {
                          fill: rgb(180, 164, 17);
                      }
  
                      &--heat7 {
                          fill: rgb(39, 104, 23);
                      }
  
                      &--heat8 {
                          fill: rgb(15, 66, 107);
                      }
  
                      &--heat9 {
                          fill: rgb(52, 3, 116);
                      }
  
                      &--heat10 {
                          fill: rgb(173, 91, 13);
                      }
  
                      &:focus,
                      &:hover {
                          opacity: 0.75;
                      }
                  }
              }
  
              &__tooltip {
                  position: fixed;
                  width: 200px;
                  padding: 10px;
                  border: 1px solid darkgray;
                  background-color: white;
              }
          }
      }
  }