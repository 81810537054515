@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	/* --primary : rgb(221, 19, 63);
    --light-primary : rgb(251, 42, 122);
    --light : rgba(153, 238, 195, 0.027); */
	--primary: rgb(32, 189, 89);

	--light-primary: rgb(251, 42, 122);
	--danger: rgb(240, 12, 99);
	--light: rgba(153, 238, 195, 0.027);
}

.text-primary {
	color: var(--primary);
}

.bg-primary {
	background-color: var(--primary);
	color: white;
}

.bg-light-primary {
	background-color: var(--light-primary);
}

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

table {
	/* position: relative !important; */
	/* border-collapse: collapse !important; */
	/* min-height: 800px !important; */
}

table thead {
	/* position: fixed !important;
	width: 800px !important;  */
	/* top: 100 !important;
	/* background-color: red; */
}

table thead th {
	font-size: 15px !important;
	font-weight: bold !important;
	color: black !important;
}


table td,
th {

	text-transform: lowercase !important;
	max-width: 12vw;
	text-wrap: nowrap !important;
	overflow: hidden;
	text-overflow: ellipsis;
	/* padding: 5px !important; */
}

table th::first-letter,
td::first-letter {
	text-transform: capitalize !important;
}

.fs-c {
	text-transform: uppercase !important;
}

.fs-cf::first-letter {
	text-transform: uppercase !important;

}





tr:hover {
	/* text-wrap: nowrap;
	overflow: unset;
	text-overflow: unset; */
	/* font-weight: bold;
	 */
	background-color: rgba(0, 223, 52, 0.10);
	/* background-color: rgba(221, 19, 63,0.10); */
	box-shadow: 2px 0px 6px rgba(46, 231, 101, 0.384);
	color: black;
	cursor: pointer;
}

.css-xumdn4 {
	text-transform: uppercase !important;
}

.text-danger {
	color: var(--primary) !important;

}

.bg-lightPrimary {
	/* background-color: rgba(86, 100, 117, 0.075) !important; */
	background-color: var(--light) !important;
}


.tableFixHead {
	overflow-y: auto;
	/* make the table scrollable if height is more than 200 px  */
	height: 80vh;
	/* gives an initial height of 200px to the table */
}

.tableFixHead thead th {
	/* position: sticky; make the table heads sticky */
	top: 0px;
	/* table head will be placed from the top of the table and sticks to it */
	z-index: 1;
	visibility: visible;
}

table {
	/* border-collapse: collapse; */
	/* make the table borders collapse to each other */
	/* width: 100%; */
}

th,
td {
	/* padding: 8px 16px; */
	/* border-block: 1px solid #ccc; */
}

th {
	background: #eee;
}


.bg-brand-500,
.chakra-button {
	background-color: var(--primary) !important;
	color: white !important;
}

.btn-danger {
	background-color: var(--danger) !important;

}

.text-brand-500 {
	color: var(--primary) !important;
}


.searching-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(165, 71, 71, 0.103) !important;
	height: 100dvh;
	width: 100%;
	border-radius: 0%;
	z-index: 99 !important;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	backdrop-filter: blur(4px);
	transition: all 0.2s !important;

}

#search input {
	/* width:   400px !important; */
	display: none;
	transition: all 0.2s !important;
}


.searching {
	width: 60% !important;
	height: 60px !important;
	background-color: white;
	border-radius: 15px;
	margin-block: 1%;
	box-shadow: 1px 5px 15px rgba(54, 34, 34, 0.6);
	transition: all 0.2s !important;

}

.searching input {
	width: 100% !important;
	display: block !important;
	transition: all 0.2s !important;

}

#search-result {
	transition: all 0.3s !important;
}

#search-result .content {
	padding-inline: 3%;

	height: 70dvh !important;
	overflow: auto !important;

}


#search-result a {
	transition: all 0.1s !important;
	border-radius: 3px;
}

#search-result a:hover {
	background-color: rgba(255, 0, 0, 0.171);
	transform: scale(1.009);
	box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.329);
	/* margin-block: 5px; */
	padding-block: 10px;
}

.filter-item {
	min-width: 10rem !important;
}

.anime {
	opacity: 0;
	animation-duration: 300ms;
	animation-fill-mode: forwards;
}

.anime.fadeIn {
	animation-name: fadeIn;
}

.anime.slideRight {
	animation-name: slideRight;
}

.anime.slideLeft {
	animation-name: slideLeft;
}

.anime.slideDown {
	animation-name: slideDown;
}

.anime.slideUp {
	animation-name: slideUp;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slideRight {
	from {
		opacity: 0;
		transform: translateX(100%);
		/* Element starts 100% off-screen to the right */
	}

	to {
		opacity: 1;
		transform: translateX(0);
		/* Element slides to its original position */
	}
}

@keyframes slideUp {
	from {
		opacity: 0;
		transform: translateY(100%);
		/* Element starts 100% off-screen to the right */
	}

	to {
		opacity: 1;
		transform: translateY(0);
		/* Element slides to its original position */
	}
}

@keyframes slideDown {
	from {
		opacity: 0;
		transform: translateY(-100%);
		/* Element starts 100% off-screen to the right */
	}

	to {
		opacity: 1;
		transform: translateY(0);
		/* Element slides to its original position */
	}
}

@keyframes slideLeft {
	from {
		opacity: 0;
		transform: translateX(-100%);
		/* Element starts 100% off-screen to the right */
	}

	to {
		opacity: 1;
		transform: translateX(0);
		/* Element slides to its original position */
	}
}


.css-hz1bth-MuiDialog-container,
.css-ekeie0 {
	align-items: start !important;
}

.capitalize-none {
	text-transform: unset !important;
	text-transform: none !important;
}



/* .MuiAutocomplete-option{
	background-color: yellow !important;
	opacity: 1;
	z-index: 9999;
} */

.bg-blur-sm {
	backdrop-filter: 'blur(9px)';
}

.bg-blur-lg {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	/* Ensure text is readable */
	backdrop-filter: blur(5px);
	/* Apply blur filter */
}

.bg-blur-white {
	/* background-color: rgba(220,220,20,0.2); */
	background-color: rgba(155, 229, 188, 0.7);

	backdrop-filter: blur(15px);

}

.bg-blur-white2 {
	/* background-color: rgba(220,220,20,0.2); */
	background-color: rgba(195, 243, 217, 0.7);

	backdrop-filter: blur(15px);

}

.bg-blur-dark {
	/* background-color: rgba(255,255,255,0.7); */
	background-color: rgba(255, 255, 255);

	/* opacity: 0.5; */
	backdrop-filter: blur(15px);

}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
	padding: 0 !important;
}