
.sidebar{
    /* background-color: rgb(59 130 246 / 0.1) !important; */
    /* overflow: auto;
    height: 200px; */
    overflow: auto;
    height: 80vh;
}

.sidebar ul{
    /* background-color: rgb(59 130 246 / 0.1) !important; */
}
.sidebar .sidebar-label{
    color: black;
}
.sidebar .sidebar-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	background-color: rgba(255, 255, 255, 1);
	
	backdrop-filter: blur(15px);

    margin-inline: 14px;
    width: 10rem;
    height: 70px;
    border: 2px solid rgb(226, 222, 222);
    border-radius: 15px;
    box-shadow: 0px 3px 10px 2px rgba(0,0,0,0.1);
    transition : all 200ms ease-in-out;
}
.sidebar .sidebar-link:hover{
    border: 2px solid var(--primary);
}
.sidebar .sidebar-link-active {
    transform: scale(1.08);
    /* background-color: rgb(66, 42 ,251) !important; */
    background-color: var(--primary)!important;
    /* box-shadow: 1px 2px 4px 3px rgba(250, 250, 250, 0.4); */


}
.sidebar .sidebar-link-loader {
    /* transform: scale(1.08); */
    /* background-color: rgb(66, 42 ,251) !important; */
    background-color:unset !important;
    /* box-shadow: 1px 2px 4px 3px rgba(250, 250, 250, 0.4); */


}
.sidebar .sidebar-link-active .sidebar-label{
    color: white !important;
}

.sidebar .sidebar-link .sidebar-icon{
    color: black;
    /* transition-duration: 300ms ; */
}
.sidebar .sidebar-link .sidebar-icon svg{
    /* height: 100px; */
}
.sidebar .sidebar-link-active .sidebar-icon{
    color: white !important;
    transform: scale(1.3);

}

.sidebar .sidebar-link .sidebar-label{
    /* color: brown; */
}


.side-bar .Mui-selected{
    transition-duration: 300ms !important;
    background-color: var(--primary) !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;

}